import jsonService from './jsonService';
import authenticationService from './AuthenticationService';
export class PkiService {
    // _requestPerMonth : any[] | undefined = undefined;
    // generate(year: number, numberOfMonth? : number) {
    //     if(! numberOfMonth) {
    //         numberOfMonth = 12;
    //     }
    //     let yearStat : any = { year : year, perMonths: []}
    //     for (let index = 0; index < numberOfMonth; index++) {
    //         const monthStat : any = {month: index+1, perThemes:[]};
    //         for (let theme = 0; theme < 11; theme++) {
    //             const themeStat = {theme: theme+1, count: PkiService.getRandomInt(10)};
    //             monthStat.perThemes.push(themeStat);
    //         }
    //         yearStat.perMonths.push(monthStat);
    //     }
    //     return yearStat;
    // }

    // constructor() {
    //     this._requestPerMonth = [ this.generate(2019), this.generate(2020, 3)];
    // }
    
    // static getRandomInt(max:number) {
    //     return Math.floor(Math.random() * Math.floor(max));
    // }

    async getRequestPerMonth() {
        const response = await jsonService.get('/kpi/requestPerMonths/' + new Date().getFullYear(), authenticationService.prepareRequest({}));
        const data : any = await response.json();
        return data;       
    }

    async getAverageDurations(theme?: number) {
        let uri = '/kpi/averageTicketDuration/' + new Date().getFullYear() + '/';
        if(theme) {
            uri += '' + theme;
        }
        const response = await jsonService.get(uri, authenticationService.prepareRequest({}));
        const data : any = await response.json();
        return data;        
    }

    async getPriseEnCharge(theme : number | undefined | null, from : Date, until : Date)
    {
        let uri = '/kpi/priseEnCharge'
        const response = await jsonService.post(uri, authenticationService.prepareRequest({}), {theme : theme, from : from, until : until});
        const data : any = await response.json();
        return data;
    }

    async exportPriseEnCharge(theme : number | undefined | null, from : Date, until : Date)
    {
        let uri = '/kpi/priseEnCharge/export'
        const response = await jsonService.post(uri, authenticationService.prepareRequest({}), {theme : theme, from : from, until : until});
        
        if (!response.ok) {
            throw new Error('Erreur lors de la récupération du fichier');
        }
    
        // Retourne la réponse en blob pour un fichier binaire
        return await response.blob();
    }
}

const pkiService = new PkiService();
export default pkiService;