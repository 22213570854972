import React, {Component} from 'react';

type NotFoundProps = {
    component?: string
}

export default class NotFound extends Component<NotFoundProps, any>
{
    render() {
        return (<div className="container">component <b>{this.props.component}</b> not found</div>);
    }
}