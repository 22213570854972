import React, { Component } from 'react';
import pkiService from './services/PkiService';
import ThemeList from './ticket-form/ThemeList';
import { Redirect } from 'react-router-dom';

import { Row, Col, Card } from 'react-bootstrap';
import { StatCard } from './tickets/StatCard';
import authenticationService from './services/AuthenticationService';
import themeService from './services/ThemeService';
import { withTranslation, WithTranslation } from 'react-i18next';
import FontAwesomeIcon from './FontAwesome';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { registerForIntl } from '@progress/kendo-react-intl';
type PriseEnChargeState = {
    data: any,
    percentages : any,
    theme: any | undefined,
    themeLabels: string[],
    from:Date,
    until:Date,
    themeStats: any[]
}

export class PriseEnCharge  extends Component<any & WithTranslation, PriseEnChargeState> {
    
    constructor(props: any) {
        super(props);
        this.state = {
            data: {"processed" : {total:0}, "unprocessed" : {total:0, newRequests : 0, assignedRequests : 0, acceptedRequests : 0}},
            theme: undefined,
            themeLabels: [],
            percentages : {"processed" :0, "unprocessed":0},
            from : new Date(new Date().getFullYear(), 0, 1), //1er janvier de l'année en cours
            until : new Date(), //date du jour
            themeStats: []
        };
        this.onChangeTheme = this.onChangeTheme.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    async onChangeTheme(theme: any) {
        this.setState({theme: theme});
    }

    async handleSearch() {
        var data = await pkiService.getPriseEnCharge(this.state.theme?.id, this.state.from, this.state.until);
        var percentages =  {"processed" : 0, "unprocessed" : 0};
        var overall = data.processed.total + data.unprocessed.total;
        if(overall > 0)
        {
            percentages =  {"processed" : data.processed.total *100 / overall, "unprocessed" : data.unprocessed.total*100 / overall};
        }
        this.setState({ data: data, percentages : percentages});
    }

    async handleExport() {
        const themeId = this.state.theme?.id;
        const from = this.state.from;
        const until = this.state.until;

        try {
            const blob = await pkiService.exportPriseEnCharge(themeId, from, until);
    
            // Création du lien de téléchargement
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
    
            // Nom du fichier à télécharger, en cohérence avec le nom généré dans l'API
            link.setAttribute('download', `Export-Helpdesk-${from.toLocaleDateString()}-${until.toLocaleDateString()}.xlsx`);
    
            // Ajout du lien dans le DOM, clic pour déclencher le téléchargement, puis suppression du lien
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error("Erreur lors de l'exportation du fichier :", error);
        }
    }


    async componentWillMount() {
        await this.handleSearch();
    }

    render() {
        const {t} = this.props;
        const me = authenticationService.me();
        if (!authenticationService.isAuthenticated()) {
            return (<Redirect to={'/login'} />);
        }
        else if (me.role === 'franchise') {
            return (<Redirect to={'/tickets'} />);
        }
        else {
            return (
                    <div className={'main-wrapper'}>
                        <div className={'container'}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Col md={6}>
                                        <ThemeList allowNull onChange={this.onChangeTheme} value={this.state.theme} />
                                    </Col>
                                    <Col md={3}>
                                        <div className="form-group">
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">{t('searchfilter.from')}</span>
                                                </div>
                                                <DatePicker className={'form-control'} value={this.state.from}
                                                    onChange={(e: any) => { this.setState({ from: e.target.value }); }}/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className="form-group">
                                            <div className="input-group flex-nowrap">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">{t('searchfilter.until')}</span>
                                                </div>
                                                <DatePicker className={'form-control'} value={this.state.until}
                                                onChange={(e: any) => { this.setState({ until: e.target.value }); }} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer style={{textAlign:"right"}}>
                                <button type="submit" className="btn btn-primary" style={{marginLeft: "0.5rem"}} onClick={(e) => this.handleSearch()}><FontAwesomeIcon icon={'check'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('searchfilter.apply')}</span></button>
                                <button type="submit" className="btn btn-primary" style={{marginLeft: "0.5rem"}} onClick={(e) => this.handleExport()}><FontAwesomeIcon icon={'file-excel-o'} size={'fw'} /><span className="d-none d-sm-inline-block"> {t('searchfilter.export')}</span></button>
                            </Card.Footer>
                            </Card>
                            <Row style={{paddingTop:"5px",width:"100%"}}>
                                <Col md={6}>
                                    <StatCard variant={'blue'} style={{alignItems:"stretch"}}>
                                        <Row>
                                            <Col md={12} style={{textAlign:"center"}}>
                                                <div className={'stat-header'}>{t('priseEnCharge.processed.title')} - {this.state.data.processed.total} ({this.state.percentages.processed.toFixed(0)} %)</div>
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.processed.acceptedRequests')}</Col>
                                            <Col md={3}>{this.state.data.processed.acceptedRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.processed.waitingInternalRequests')}</Col>
                                            <Col md={3}>{this.state.data.processed.waitingInternalRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.processed.waitingExternalRequests')}</Col>
                                            <Col md={3}>{this.state.data.processed.waitingExternalRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.processed.rejectedRequests')}</Col>
                                            <Col md={3}>{this.state.data.processed.rejectedRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.processed.resolvedRequests')}</Col>
                                            <Col md={3}>{this.state.data.processed.resolvedRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.processed.closedRequests')}</Col>
                                            <Col md={3}>{this.state.data.processed.closedRequests}</Col>
                                        </Row>
                                    </StatCard>
                                </Col>
                                <Col md={6}>
                                    <StatCard variant={'red'} style={{alignItems:"stretch"}}>
                                        <Row>
                                            <Col md={12} style={{textAlign:"center"}}>
                                                <div className={'stat-header'}>{t('priseEnCharge.unprocessed.title')} - {this.state.data.unprocessed.total} ({this.state.percentages.unprocessed.toFixed(0)} %)</div>
                                            </Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.unprocessed.newRequests')}</Col>
                                            <Col md={3}>{this.state.data.unprocessed.newRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.unprocessed.assignedRequests')}</Col>
                                            <Col md={3}>{this.state.data.unprocessed.assignedRequests}</Col>
                                        </Row>
                                        <Row>
                                            <Col md={9}>{t('priseEnCharge.unprocessed.acceptedNoCommentRequests')}</Col>
                                            <Col md={3}>{this.state.data.unprocessed.acceptedRequests}</Col>
                                        </Row>
                                        <Row>&nbsp;</Row>
                                        <Row>&nbsp;</Row>
                                        <Row>&nbsp;</Row>
                                    </StatCard>
                                </Col>
                            </Row>
                        </div>
                    </div>
                );
        }
    }
}

registerForIntl(PriseEnCharge);
export default withTranslation()(PriseEnCharge);